<template>
  <pp-frame>
    <h2 class="blue-grey--text">
      <p>{{ $t('Congratulations!') }}</p>
    </h2>

    <div class="secondary--text">
      {{ translate(`
      Thank you for completing your new patient information!
      `) }}
      <br>
      <span v-if="!qSections.questionnaireCompleted">
        {{ translate(`Now let's work on the Questionnaire!`) }}
        {{ $t('Click the green button to start Questionnaire.') }}
      </span>
    </div>

    <template #actions>
      <v-btn to="/register/contacts">
        <v-icon>mdi-chevron-double-left</v-icon>
        {{ $t('Back') }}
      </v-btn>

      <v-spacer />

      <v-btn
        v-if="qSections.questionnaireCompleted"
        color="success"
        to="/"
      >
        {{ $t('Go to Home') }}
      </v-btn>
      <v-btn
        v-else
        color="success"
        to="/questionnaire"
      >
        {{ $t('Start Questionnaire') }}
      </v-btn>
    </template>
  </pp-frame>
</template>

<script>
import { useQuestionnaire } from '@/use/questionnaire'

export default {
  setup: () => {
    const { qSections } = useQuestionnaire()

    return { qSections }
  }
}
</script>
